<section class="welcome-content fontfam-poppins">
  <div class="left-shape">
    <img src="/assets/images/shapes/orange_shadow.png" />
  </div>
  <div class="middle-content">
    <div class="iam-cont">
      <p class="mr-16">I</p>
      <p>a</p>
      <p>m</p>
    </div>
    <div>
      <div class="prename-cont">
        <p>S</p>
        <p>e</p>
        <p>b</p>
        <p>a</p>
        <p>s</p>
        <p>t</p>
        <p>i</p>
        <p>a</p>
        <p class="mr-16">n</p>
        <p>W</p>
        <p>o</p>
        <p>l</p>
        <p>f</p>
        <p>f</p>
      </div>
      <div class="frontend-cont">
        <p>F</p>
        <p>u</p>
        <p>l</p>
        <p>l</p>
        <p>s</p>
        <p>t</p>
        <p>a</p>
        <p>c</p>
        <p class="mr-16">k</p>
        <p>D</p>
        <p>e</p>
        <p>v</p>
        <p>e</p>
        <p>l</p>
        <p>o</p>
        <p>p</p>
        <p>e</p>
        <p>r</p>
      </div>
    </div>
  </div>
  <div class="talk-btn">
    <a href="#contact">
      <button>Let's talk!</button>
    </a>
  </div>
  <div class="right-shape">
    <img src="/assets/images/shapes/purple_shadow.png" />
  </div>
</section>
<div id="aboutMe" class="cards-wrapper">
  <div class="cards-wrapped">
    <section class="section-transition">
      <div class="left-transition">
        <div class="orange-line"></div>
        <app-socilinks class="soc-links-cont"></app-socilinks>
      </div>
      <div class="right-transition">
        <p class="fontfam-poppins">Scroll down</p>
        <img src="/assets/images/icons/arrow_white.png" />
      </div>
    </section>
  </div>
</div>
